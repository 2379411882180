/* External dependencies */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedDate, FormattedMessage, injectIntl, intlShape } from "react-intl";

/* Internal dependencies */
import ButtonsContainer from "../../general/ButtonsContainer";
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import plus from "../../../icons/black-plus-thin.svg";
import minus from "../../../icons/black-minus-thin.svg";
import { InputField } from "../../InputField";
import { toSafeInteger } from "lodash/lang";
import { Button, Title, Modal, TextInput } from "@yoast/ui-library";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";


import styles from "./styles.scss";

const messages = defineMessages( {
  ariaLabel: {
    id: "subscriptionCancel.modal.arialabel",
    defaultMessage: "Cancel subscription",
  },
  header: {
    id: "subscriptionCancel.modal.header",
    defaultMessage: "Cancel subscription?",
  },
  body: {
    id: "subscriptionCancel.modal.body",
    defaultMessage: "Are you sure you want to cancel this subscription? " +
      "If you cancel, you will no longer receive support or security and functionality updates after {term}",
  },
  activeSites: {
    id: "subscriptionCancel.modal.activeSites",
    defaultMessage: "You have {amount} active {amount, plural, one {site} other {sites}} using this subscription.",
  },
  numberOfCurrentSubscriptions: {
    id: "subscriptionCancel.modal.numberSubscriptions",
    defaultMessage: "You have {amount} of this type of subscription.",
  },
  cancel: {
    id: "subscriptionCancel.modal.cancel",
    defaultMessage: "Cancel",
  },
  confirm: {
    id: "subscriptionCancel.modal.confirm",
    defaultMessage: "Confirm cancellation",
  },
  loading: {
    id: "subscriptionCancel.modal.loading",
    defaultMessage: "Cancelling subscription...",
  },
  amount: {
    id: "subscriptionCancel.modal.amount",
    defaultMessage: "Amount",
  },
  selectAmount: {
    id: "subscriptionCancel.modal.selectAmount",
    defaultMessage: "Select amount",
  },
  decreaseAmount: {
    id: "subscriptionCancel.modal.decreaseAmount",
    defaultMessage: "Decrease amount",
  },
  increaseAmount: {
    id: "subscriptionCancel.modal.increaseAmount",
    defaultMessage: "Increase amount",
  },
  activeEnrollments: {
    id: "subscriptionCancel.modal.activeEnrollments",
    defaultMessage: "You have {amount} active {amount, plural, one {enrollment} other {enrollments}} using this subscription.",
  },
} );

const SubscriptionEditModal = ( props ) => {
  const [ amountToCancel, setAmountToCancel ] = useState( 0 );

  useEffect( props.onClose, [ props.success ] );

  const cancelSubscription = useCallback( () => {
    if ( ! amountToCancel > 0 ) {
      return;
    }

    props.cancelSubscription( props.subscription.id, amountToCancel );
  }, [ props.cancelSubscription, amountToCancel, props.subscription.id ] );

  /**
   * Validates the input against the maximum number of subscriptions that is possible to cancel
   * and sets it in the state.
   *
   * @param {Object} event The change event
   *
   * @returns {void}
   */
  const changeItemsToCancel = useCallback( ( event ) => {
    event.preventDefault();
    const value = toSafeInteger( event.target.value );
    // Makes sure the value is between 0 and the maximum number of subscriptions
    setAmountToCancel( Math.min( Math.max( value, 0 ), props.numberOfCurrentSubscriptions ) );
  }, [ setAmountToCancel, props.numberOfCurrentSubscriptions ] );

  /**
   * Increase the number of items to cancel unless it is already at the maximum possible.
   *
   * @returns {void}
   */
  const incrementItemsToCancel = useCallback( () => {
    if ( amountToCancel < props.numberOfCurrentSubscriptions ) {
      setAmountToCancel( amountToCancel + 1 );
    }
  }, [ amountToCancel, setAmountToCancel, props.numberOfCurrentSubscriptions ] );

  /**
   * Decrease the number of items to cancel unless it is already at one.
   *
   * @returns {void}
   */
  const decrementItemsToCancel = useCallback( () => {
    if ( amountToCancel > 0 ) {
      setAmountToCancel( amountToCancel - 1 );
    }
  }, [ amountToCancel, setAmountToCancel ] );


  const displayNumberButtons = () => {
    if ( props.numberOfCurrentSubscriptions ) {
      return <div className={ styles.amountSelector }>
        <label htmlFor="input-number">
          <FormattedMessage
            id={ messages.selectAmount.id }
            defaultMessage={ messages.selectAmount.defaultMessage }
          />
        </label>

        <Button
          className={ styles.amountButton }
          onClick={ decrementItemsToCancel }
          variant="secondary"
          size="small"
          aria-label={ messages.decreaseAmount.defaultMessage }
        >
          <MinusIcon />
        </Button>
        <TextInput
          id="input-number"
          className={ styles.amountInput }
          value={ amountToCancel }
          onChange={ changeItemsToCancel }
          type="number"
          aria-label={ messages.amount.defaultMessage }
        />
        <Button
          className={ styles.amountButton }
          onClick={ incrementItemsToCancel }
          variant="secondary"
          size="small"
          aria-label={ messages.increaseAmount.defaultMessage }
        >
          <PlusIcon />
        </Button>
      </div>;
    }
  };

  const confirmButtonText        = props.loading ? messages.loading : messages.confirm;
  const isEnabled                = props.loading === false && amountToCancel > 0;
  const { nextPayment, endDate } = props.subscription;
  const paymentTermDate          = <FormattedDate
    value={ nextPayment ? nextPayment : endDate }
    year="numeric"
    month="long"
    day="2-digit"
  />;

  const modalContents = (
    <Modal.Panel>
      <Modal.Title>
        <FormattedMessage { ...messages.header } />
      </Modal.Title>
      <div className={ styles.cancelForm }>
        <p>
          <FormattedMessage
            { ...messages.body }
            values={ { term: <strong>{ paymentTermDate }</strong> } }
          />
        </p>
        <p>
          <strong>
            <FormattedMessage
              { ...messages.activeSites }
              values={ { amount: props.amountOfActiveSites } }
            />
          </strong>
        </p>
        <p>
          <strong>
            <FormattedMessage
              { ...messages.activeEnrollments }
              values={ { amount: props.numberOfActiveEnrollments } }
            />
          </strong>
        </p>
        <p>
          <FormattedMessage
            { ...messages.numberOfCurrentSubscriptions }
            values={ { amount: props.numberOfCurrentSubscriptions } }
          />
        </p>
        { displayNumberButtons() }
        <ErrorDisplay error={ props.error } />
        <ButtonsContainer>
          <Button onClick={ props.onClose }>
            <FormattedMessage { ...messages.cancel } />
          </Button>
          <Button
            type="submit"
            onClick={ cancelSubscription }
            className={ isEnabled ? styles.enabled : styles.disabled }
            disabled={ ! isEnabled }
            variant="error"
          >
            <FormattedMessage { ...confirmButtonText } />
          </Button>
        </ButtonsContainer>
      </div>
    </Modal.Panel>
  )

  return <Modal isOpen={ props.isOpen } onClose={ props.onClose} position="center">
    { modalContents }
  </Modal>
};

SubscriptionEditModal.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: ErrorPropTypeShape,
  amountOfActiveSites: PropTypes.number.isRequired,
  numberOfCurrentSubscriptions: PropTypes.number.isRequired,
  numberOfActiveEnrollments: PropTypes.number.isRequired,
  subscription: PropTypes.object.isRequired,
};

SubscriptionEditModal.defaultProps = {
  isOpen: false,
  error: null,
};

export default injectIntl( SubscriptionEditModal );
