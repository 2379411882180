import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, FormattedDate, injectIntl, intlShape } from "react-intl";
import { orderBy } from "lodash/collection";
import { ArrowPathIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { Button, Link, Table } from "@yoast/ui-library";

import AnimatedLoader from "../Loader";
import { hasDownload } from "shared-frontend/functions/productGroups";
import { subscriptionShape } from "../account/subscriptions/SubscriptionPropTypeShape";
import Transaction from "../../types/Transaction";

import { PageHeader } from "../PageHeader";
import { PageSection } from "../PageSection";
import { BackButton } from "../BackButton";
import { SubscriptionSection } from "./SubscriptionSection";
import InvoicesDownloadContainer from "../../containers/InvoicesDownload";

import { UpgradeSubscriptionModal } from "./UpgradeSubscription";
import SubscriptionEditModal from "../../containers/SubscriptionEditModal";
import AddSiteToSubscriptionModal from "../../containers/AddSiteToSubscriptionModal";

import { generateRenewalUrl } from "../../functions/generateRenewalUrl";
import {
	generateSwitchPaymentMethodUrl,
	generateSwitchToAutomaticUrl,
} from "../../functions/subscriptionActionLinks";

import formatAmount from "../../functions/currency";

import { messages } from "./messages";

import styles from "./styles.scss";

const SubscriptionPage = ( props ) => {
	const [ products, setProducts ] = useState( { plugins: [], courses: [] } );
	const [ isCoursesOpen, setCoursesIsOpen ] = useState( false );
	const [ isPluginsOpen, setPluginsIsOpen ] = useState( false );
	const [ addSiteModalOpen, setAddSiteModalOpen ] = useState( false );
	const [ upgradeToBundleModalOpen, setUpgradeToBundleModalOpen ] = useState( false );
	const [ cancelModalOpen, setCancelModalOpen ] = useState( false );

	useEffect( () => {
		props.loadData();
	}, [] );

	useEffect( () => {
		setProducts( {
			plugins: props.products.filter( product => ! product.isCourse ),
			courses: props.products.filter( product => product.isCourse ),
		} );
	}, [ props ] );

	const onManageHandler = ( siteId ) => {
		props.onManage( siteId );
	};

	const getFormattedStatus = ( status ) => {
		const buttonClass = classNames( styles.status, { [styles.active]: status === "active" } );
		return <Button variant="secondary" className={ buttonClass } size="small" as="span"
					   disabled>{ status }</Button>;
	};

	const formatDate = ( date ) => {
		return date ?
				<FormattedDate
						value={ new Date( date ) }
						year="numeric"
						month="long"
						day="2-digit"
				/>
				: "";
	};

	const { subscription, isLoading, sites, connectedSubscriptions } = props;
	const hasActiveSiblings = connectedSubscriptions.some(
			subscription => ! [ "expired", "cancelled", "refunded", "pending-cancel" ].includes( subscription.status )
	);
	const sitesByName = orderBy( sites, "url", "asc" );


	if ( ! subscription && isLoading ) {
		return <AnimatedLoader/>;
	}

	const extendSubscriptionLink = (
			<Link href={ generateRenewalUrl( subscription ) } className={ styles.renewLink }>
				<Button variant="secondary">
					<ArrowPathIcon/>
					<FormattedMessage
							{ ...messages.extendSubscription }
							values={ {
								billingTerm: subscription.product.billingTerm,
							} }
					/>
				</Button>
			</Link>
	);

	const switchToAutomaticLink = (
			<Link
					href={ generateSwitchToAutomaticUrl( props.subscription ) }
					target="_blank"
			>
				<Button variant="secondary">
					<FormattedMessage { ...messages.switchToAutomatic } />
				</Button>
			</Link>
	);

	const switchPaymentMethodLink = (
			<Link href={ generateSwitchPaymentMethodUrl( subscription ) } className={ styles.renewLink }
				  target="_blank">
				<Button variant="secondary">
					<CreditCardIcon/>
					<FormattedMessage
							{ ...messages.switchPaymentMethod }
							values={ {
								billingTerm: subscription.product.billingTerm,
							} }
					/>
				</Button>
			</Link>
	);

	const upgradeToBundleActionLink = ( subscription.status === "active" && ! hasActiveSiblings ) ?
			( <Button variant="secondary" onClick={ () => setUpgradeToBundleModalOpen( true ) }>
				<FormattedMessage { ...messages.upgradeToBundleLink } />
			</Button> )
			: null;

	const addSiteToSubscriptionAction = ( subscription.status !== "pending-cancel" && subscription.product.glNumber !== "82109" ) ?
			( <Button
					variant="secondary"
					onClick={ () => setAddSiteModalOpen( true ) }>
				<FormattedMessage { ...messages.addSiteToSubscriptionLink } />
			</Button> )
			: null;

	const cancelButton = ( <Button variant="error" onClick={ () => setCancelModalOpen( true ) }>
		{ props.intl.formatMessage( messages.cancelLink ) }
	</Button> );

	const subscriptionDetailLines = [
		{ order: 1, title: props.intl.formatMessage( messages.startDate ), value: formatDate( subscription.startDate ) },
		{ order: 2, title: props.intl.formatMessage( messages.billing ), value: subscription.lastBillingTerm },
	];

	if ( upgradeToBundleActionLink ) {
		subscriptionDetailLines.push( {
			order: 5,
			title: props.intl.formatMessage( messages.upgradeToBundleLabel ),
			value: "",
			action: upgradeToBundleActionLink
		} );
	}

	if ( subscription.requiresManualRenewal ) {
		subscriptionDetailLines.push( { title: props.intl.formatMessage( messages.switchToAutomaticLabel), value: '', action: switchToAutomaticLink } );
	}

	if ( addSiteToSubscriptionAction ) {
		subscriptionDetailLines.push( {
			order: 4,
			title: props.intl.formatMessage( messages.addSiteToSubscription ),
			value: "",
			action: addSiteToSubscriptionAction
		} );
	}

	if ( subscription.status !== "pending-cancel" ) {
		subscriptionDetailLines.push( {
			order: 7,
			title: props.intl.formatMessage( messages.cancelLink ),
			value: "",
			action: cancelButton
		} );

		subscriptionDetailLines.push( {
			order: 6,
			title: props.intl.formatMessage( messages.paymentMethod ),
			value: "",
			action: switchPaymentMethodLink
		} );

		subscriptionDetailLines.push( {
			order: 3,
			title: props.intl.formatMessage( messages.nextBilling ),
			value: formatDate( subscription.nextPayment ),
			action: extendSubscriptionLink
		} );
	}

	return (
			<section className={ styles.subscription }>
				<PageHeader
						title={ { ...messages.title, defaultMessage: subscription.name } }
						message={ { ...messages.subtitle, defaultMessage: subscription.limit + " site subscription" } }
				>
					<BackButton/>
				</PageHeader>
				<SubscriptionSection
						title={ messages.subscriptionDetailsTitle }
						lines={ [
							{
								title: props.intl.formatMessage( messages.subscriptionNumber ),
								value: subscription.subscriptionNumber
							},
							{
								title: props.intl.formatMessage( messages.subscriptionStatus ),
								value: getFormattedStatus( subscription.status )
							}
						] }
				/>
				<SubscriptionSection
						title={ messages.paymentDetailsTitle }
						lines={ subscriptionDetailLines.sort((a, b) => a.order - b.order) }
				/>
				<PageSection title={ messages.invoicesTitle }>
					<Table>
						<Table.Body>
							<Table.Row>
								<Table.Header>{ props.intl.formatMessage( messages.orderDate ) }</Table.Header>
								<Table.Header>{ props.intl.formatMessage( messages.orderId ) }</Table.Header>
								<Table.Header>{ props.intl.formatMessage( messages.orderItems ) }</Table.Header>
								<Table.Header>{ props.intl.formatMessage( messages.orderTotal ) }</Table.Header>
								<Table.Header>{ props.intl.formatMessage( messages.orderStatus ) }</Table.Header>
								<Table.Header>{ "" }</Table.Header>
							</Table.Row>
							{ props.transactions.map( ( transaction, i ) => {
								return (
										<Table.Row key={ `row-${ i }` }>
											<Table.Cell>{ transaction.date }</Table.Cell>
											<Table.Cell>{ transaction.invoiceNumber }</Table.Cell>
											<Table.Cell>{
												transaction.items.map( ( item, i ) => {
													return <div key={ `item-${ i }` }>{ item.productName } </div>;
												} )
											}</Table.Cell>
											<Table.Cell><FormattedNumber
													value={ formatAmount( transaction.totalAmount ) } style="currency"
													currency={ transaction.currency }/></Table.Cell>
											<Table.Cell>{ transaction.status }</Table.Cell>
											<Table.Cell><InvoicesDownloadContainer resourceId={ transaction.id }
																				   type={ transaction.type }/></Table.Cell>
										</Table.Row>
								);
							} ) }
						</Table.Body>
					</Table>
				</PageSection>
				<PageSection title={ messages.downloadTitle }>
					{
							products.courses.length > 0 &&
							<Link onClick={ () => setCoursesIsOpen( ! isCoursesOpen ) }
								  className={ classNames( styles.expandableHeader, styles.first, { [styles.isOpen]: isCoursesOpen } ) }>
								<span>{ props.intl.formatMessage( messages.yoastCoursesTitle ) }</span>
								{ isCoursesOpen ? <ChevronUpIcon/> : <ChevronDownIcon/> }
							</Link>
					}
					{
							isCoursesOpen &&
							<SubscriptionSection
									lines={
										products.courses.map( course => {
											return { title: course.name.replace( /(&amp;)/, "&" ) };
										} )
									}
							/>
					}
					{
							products.plugins.length > 0 &&
							<Link onClick={ () => setPluginsIsOpen( ! isPluginsOpen ) }
								  className={ classNames( styles.expandableHeader, { [styles.isOpen]: isPluginsOpen } ) }>
								<span>{ props.intl.formatMessage( messages.yoastPluginsTitle ) }</span>
								{ isPluginsOpen ? <ChevronUpIcon/> : <ChevronDownIcon/> }
							</Link>
					}
					{ isPluginsOpen &&
							<SubscriptionSection
									title={ null }
									lines={
										[
											...products.plugins.map( plugin => {
												const updatedPluginName = plugin.name.replace( /(&amp;)/, "&" );
												const action = hasDownload( plugin ) ? (
																<Link href={ plugin.downloads[0].file }>
																	<Button variant="secondary">
																		<FormattedMessage { ...messages.downloadLinkText } />
																	</Button>
																</Link> )
														: null;
												return { title: updatedPluginName, action: action };
											} ),
											{
												action: <Link href={ "https://yoa.st/myyoast-installation" }
															  target="_blank">
													<FormattedMessage { ...messages.installationGuide } />
												</Link>
											}
										]
									}
							/>
					}
				</PageSection>

				<PageSection title={ messages.connectedSites }>
					<Table>
						<Table.Body>
							{ sitesByName.length ?
									sitesByName.map( ( site, i ) => {
										return (
												<Table.Row key={ `site-${ i }` } className={ styles.sitesRow }>
													<Table.Cell>{ site.url }</Table.Cell>
													<Table.Cell className={ styles.sitesTableAction }>
														<Button variant="secondary" size="large"
																onClick={ () => onManageHandler( site.id ) }>Manage</Button>
													</Table.Cell>
												</Table.Row>
										);
									} )
									: <Table.Row><Table.Cell>
										{ props.intl.formatMessage( messages.noConnectedSites ) }
									</Table.Cell></Table.Row>
							}
						</Table.Body>
					</Table>
				</PageSection>

				<SubscriptionEditModal
						subscriptionId={ subscription.id }
						isOpen={ cancelModalOpen }
						onClose={ () => setCancelModalOpen( false ) }
				/>
				<AddSiteToSubscriptionModal
						subscriptionId={ subscription.id }
						isOpen={ addSiteModalOpen }
						onClose={ () => setAddSiteModalOpen( false ) }
				/>
				<UpgradeSubscriptionModal
						subscriptionId={ subscription.id }
						isOpen={ upgradeToBundleModalOpen }
						onClose={ () => setUpgradeToBundleModalOpen( false ) }
				/>
			</section>
	);
};

SubscriptionPage.propTypes = {
	intl: intlShape.isRequired,
	loadData: PropTypes.func.isRequired,
	onManage: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	subscription: subscriptionShape,
	sites: PropTypes.arrayOf( PropTypes.object ),
	orders: PropTypes.arrayOf( PropTypes.object ),
	transactions: PropTypes.arrayOf( Transaction ),
	products: PropTypes.arrayOf( PropTypes.object ),
	connectedSubscriptions: PropTypes.arrayOf( subscriptionShape ),
	connectedSubscriptionsSites: PropTypes.array,
};

SubscriptionPage.defaultProps = {
	isLoading: false,
	subscription: null,
	orders: [],
	transactions: [],
	sites: [],
	connectedSubscriptions: [],
	connectedSubscriptionsSites: [],
	products: [],
};

export default injectIntl( SubscriptionPage );
