import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Button, Link } from "@yoast/ui-library";

import formatAmount from "../../../functions/currency";

import SubscriptionDetails from "./SubscriptionDetails";
import messages from "./messages";

import styles from "./styles.scss";

/**
 * Creates a subscription component
 *
 * @param {object} props Properties of the component.
 * @returns {ReactElement} Subscription component.
 * @constructor
 */
const Subscription = ( props ) => {
	function isActive( status, includePending = true ) {
		const activeStatus = [ "active" ];
		if ( includePending ) {
			activeStatus.push( "pending-cancel" );
		}
		return activeStatus.includes( status );
	}

	function getManageButtons( showManageButton, onManage ) {
		if ( ! showManageButton ) {
			return <div className={ styles.styledSpace } />;
		}

		return <Button
				className={ styles.manageButton }
				onClick={ onManage }
				aria-label={ props.intl.formatMessage( messages.manage ) }
		>
			<span className={ styles.buttonText }>{ props.intl.formatMessage( messages.manage ) }</span>
			<span className={ styles.buttonIcon }><ChevronRightIcon/></span>
		</Button>;
	}

	function getNextBilling( status, endDate, nextPayment, amount ) {
		if ( status === "pending-cancel" ) {
			return <FormattedMessage { ...messages.endsDate } values={ { endDate: endDate } }/>;
		}

		if ( nextPayment && amount ) {
			return nextPayment;
		}

		return null;
	}

	function getStatus( subscription ) {
		if ( subscription.name.toLowerCase().includes( "shopify" ) && props.subscriptionsArray.length !== 1 ) {
			return "Managed by Shopify";
		}

		if ( ! props.needsAttention ) {
			if ( subscription.status === "pending-cancel" ) {
				return <FormattedMessage { ...messages.pendingCancellation } />;
			} else {
				return subscription.status;
			}
		}

		if ( subscription.status === "on-hold" ) {
			return <FormattedMessage { ...messages.suspended } />;
		}

		if ( subscription.status === "expired" ) {
			return <FormattedMessage { ...messages.expired } />;
		}

		return <FormattedMessage { ...messages.expiresSoon } />;
	}

	function getPrimaryColumnContent( subscription, nextPaymentInformation = "" ) {
		const status = getStatus( subscription );

		return (
				<Fragment>
					{ isActive( subscription.status, false ) &&
							<div className={ styles.subscriptionPaymentDetails }>
								<strong>
									<FormattedMessage
											id={ "mobile-expandable-next-billing" }
											defaultMessage={ "Next billing: " }
									/>
								</strong>
								{ nextPaymentInformation }
								{ subscription.billingType }
							</div>
					}
					{ subscription.name }
					<div>
						<span className={ classNames( styles.styledStatus, {
							[styles.needsAttention]: props.needsAttention,
							[styles.isInactive]: props.isInactive
						} ) }>
							{ status }
						</span>
						{ subscription.subscriptionNumber && ` - ${ subscription.subscriptionNumber }` }
					</div>
				</Fragment>
		);
	}

	function getDetailsLabel() {
		if ( props.needsAttention || props.isInactive ) {
			return props.intl.formatMessage( messages.details );
		}
		return props.intl.formatMessage( messages.nextPaymentOn );
	}


	function getColumnInformation( nextPaymentInformation ) {
		if ( props.needsAttention || props.isInactive ) {
			return <SubscriptionDetails subscription={ props.subscriptionsArray[0] }
										showDetailsModal={ props.showDetailsModal }/>;
		}
		return nextPaymentInformation;
	}

	function getRegularColumns( subscription, billingType, nextPaymentInformation ) {
		return (
				<Fragment>
					<div className={ styles.activeSites } key={ subscription.id + "-sites" }>
						{ subscription.hasSites && isActive( subscription.status )
								? subscription.used + "/" + subscription.limit + " sites"
								: ""
						}
					</div>
					<div className={ styles.billingType } key={ subscription.id + "-billingtype" }>
						{ billingType }
					</div>
					<div className={ styles.nextPayment } key={ subscription.id + "-details" }
						 aria-label={ getDetailsLabel() }>
						{ getColumnInformation( nextPaymentInformation ) }
					</div>
				</Fragment>
		);
	}

	function getProvisionedColums( subscription ) {
		const provisionerData = props.provisionerData[subscription.provisionerId];

		return (
				<Fragment>
					<div className={ styles.provisionerLink } key={ subscription.id + "-provisioner" }>
						{ provisionerData &&
								<Link href={ provisionerData.customerPlatformUrl } target="_blank">
									{ props.intl.formatMessage( messages.gotoProvisioner, { name: provisionerData.displayName } ) }
								</Link> }
					</div>
					<div className={ styles.billingType } key={ subscription.id + "-empty" }/>
				</Fragment>
		);
	}

	const subscription = props.subscriptionsArray[0];
	const status = subscription.status;
	const showManageButton = subscription.showManageButton;

	let nextPayment = null;
	let endDate = null;
	let amount = null;
	let billingType = null;

	if ( status === "pending-cancel" ) {
		endDate = <FormattedDate
				value={ subscription.hasEndDate && subscription.endDate }
				year="numeric"
				month="long"
				day="numeric"
		/>;
	}

	if ( subscription.status === "active" && ( subscription.hasNextPayment || subscription.hasEndDate ) ) {
		nextPayment = <FormattedDate
				value={ subscription.hasNextPayment ? subscription.nextPayment : subscription.endDate }
				year="numeric"
				month="long"
				day="numeric"
		/>;
		amount = <FormattedNumber
				value={ formatAmount( subscription.billingAmount ) }
				currency={ subscription.billingCurrency }
				style="currency"
		/>;
		billingType = subscription.requiresManualRenewal ? "Manual renewal" : "Automatic renewal";
	}

	const nextPaymentInformation = getNextBilling( status, endDate, nextPayment, amount );

	return (
			<div className={ styles.subscription }>
				<div>
					<div className={ styles.icon }>
						<img src={ subscription.icon } alt=""/>
					</div>
				</div>
				<div className={ styles.primary } key={ subscription.id + "-primary" }>
					{ getPrimaryColumnContent( subscription, nextPaymentInformation ) }
				</div>
				{ props.isProvisioned
						? getProvisionedColums( subscription )
						: getRegularColumns( subscription, billingType, nextPaymentInformation )
				}
				<div className={ styles.fixedWidth }>
					{ ( props.needsAttention || props.isInactive ) && ! props.isProvisioned
							? getManageButtons( showManageButton, () => props.showDetailsModal( subscription ) )
							: getManageButtons( showManageButton, () => props.onManage( subscription.id ) )
					}
				</div>
			</div>
	);
};

Subscription.propTypes = {
	onManage: PropTypes.func.isRequired,
	subscriptionsArray: PropTypes.array.isRequired,
	provisionerData: PropTypes.object,
	intl: intlShape.isRequired,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
};

Subscription.defaultProps = {
	provisionerData: {},
	showDetailsModal: null,
	isProvisioned: false,
	needsAttention: false,
	isInactive: false,
};

export default injectIntl( Subscription );
