// External dependencies.
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate, defineMessages, injectIntl, intlShape } from "react-intl";
import Cookies from "js-cookie";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Title, Card, Button, Link, Table } from "@yoast/ui-library";

// Internal dependencies.
import renewalNotification from "../../images/renewalNotification.svg";
import caretRight from "../../icons/caret-right.svg";
import { generateRenewalUrl } from "../../functions/generateRenewalUrl";
import { createCartUrlForSubscription } from "shared-frontend/functions/cart";
import groupBy from "../../functions/groupBy";

import styles from "./styles.scss";

const messages = defineMessages( {
  header: {
    id: "renewal.notification.header",
    defaultMessage: "One or more subscriptions are about to expire!",
  },
  description: {
    id: "renewal.notification.description",
    defaultMessage: "When they do, you will no longer receive (security) updates or support!",
  },
  close: {
    id: "renewal.notification.close",
    defaultMessage: "close",
  },
  linkPlaceholder: {
    id: "renewal.notification.link.placeholder",
    defaultMessage: "{renewalLink}",
  },
  linkMessage: {
    id: "renewal.notification.link.message",
    defaultMessage: "Renew now",
  },
  alternateLinkMessage: {
    id: "renewal.notification.link.alternateMessage",
    defaultMessage: "Buy subscription",
  },
  productHeader: {
    id: "headers.product",
    defaultMessage: "Subscription number",
  },
  expiryHeader: {
    id: "headers.expiry",
    defaultMessage: "Expires on",
  },
} );

/**
 * Renewal notification message
 *
 * To help the customer understand what happened to licenses.
 *
 * @param {object} props Properties of the component.
 * @returns {ReactElement} Subscription component.
 * @constructor
 */
class RenewalNotification extends React.Component {
  /**
   * Initializes the renewal notification.
   */
  constructor()  {
    super();
    this.state = {
      hideNotification: false,
    };
    this.onCrossClick = this.onCrossClick.bind( this );
  }

  /**
   * Hides notification after component mount if the notification was clicked away before.
   *
   * @returns {void}
   */
  componentDidMount() {
    if ( Cookies.get( "hideRenewalNotification" ) ) {
      this.setState( { hideNotification: true } );
    }
    if ( ! this.props.allSubscriptions ) {
      this.props.loadData();
    }
  }

  /**
   * Called on cross click.
   *
   * @returns {void}
   */
  onCrossClick() {
    Cookies.set( "hideRenewalNotification", "true", { expires: 7 } );
    this.setState( { hideNotification: true } );
  }

  subscriptionRenewalRow( renewals ) {
    const firstRenewal = renewals[ 0 ];
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth( oneMonthAgo.getMonth() - 1 );
    const expiredMoreThanOneMonthAgo = ( firstRenewal.nextPayment && firstRenewal.nextPayment < oneMonthAgo ) ||
      ( firstRenewal.endDate && firstRenewal.endDate < oneMonthAgo );
    const subscriptionNumber = firstRenewal.subscriptionNumber;

    return (
      <>
        <Table.Row key={ subscriptionNumber } className={ styles.table }>
          <Table.Cell>
            <span className={ styles.cellLabel }> { this.props.intl.formatMessage( messages.productHeader ) } </span>
            #{ subscriptionNumber }
          </Table.Cell>
          <Table.Cell
          >
            <span className={ styles.cellLabel }> { this.props.intl.formatMessage( messages.expiryHeader ) } </span>
            <FormattedDate
              value={ firstRenewal.nextPayment }
              year="numeric"
              month="long"
              day="2-digit"
            />
          </Table.Cell>
          <Table.Cell className={ styles.upsell }>
            <Link
              href={ expiredMoreThanOneMonthAgo ? createCartUrlForSubscription( firstRenewal ) : generateRenewalUrl( firstRenewal ) }
              target="_blank"
            >
              <Button
                variant="upsell"
                className={ expiredMoreThanOneMonthAgo ? "buy_subscription_button_cta" : "renew_now_button_cta" }
              >
                <FormattedMessage { ...( expiredMoreThanOneMonthAgo ? messages.alternateLinkMessage : messages.linkMessage ) } /> <img className={ styles.buttonIcon } src={ caretRight } />
              </Button>
            </Link>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            { renewals.map( item => (
              <p className={ styles.renewalItem } key={ item.id }>
                <img className={ styles.renewalItemImage } src={ item.product.icon } />
                <span style={ { opacity: 0.65 } }>{ item.limit } x { item.name }</span>
              </p>
            ) ) }
          </Table.Cell>
        </Table.Row>
      </>
    );
  }

  /**
   * Creates a list component with the upcoming renewals.
   *
   * @param {Array} upcomingRenewals The upcoming renewals.
   *
   * @returns {ReactElement} The upcoming renewals component.
   */
  listUpcomingRenewals( upcomingRenewals ) {
    const groupedRenewals = Object.values( groupBy( upcomingRenewals, "subscriptionNumber" ) );
    const upcomingRenewalsList = groupedRenewals.map( renewalGroup => {
      return this.subscriptionRenewalRow( renewalGroup );
    } );

    return (
      <Table className={ styles.table }>
        <Table.Body>
          <Table.Row className={ styles.header }>
          <Table.Cell>
            { this.props.intl.formatMessage( messages.productHeader ) }
          </Table.Cell>
          <Table.Cell>
            { this.props.intl.formatMessage( messages.expiryHeader ) }
          </Table.Cell>
          </Table.Row>
          { upcomingRenewalsList }
        </Table.Body>
      </Table>
    );
  }

  /**
   * Renders the message.
   *
   * @returns { ReactElement|null} Returns a message container including the renewalNotification or null.
   */
  render() {
    if ( this.props.upcomingRenewals.length < 1 ) {
      return null;
    }

    // The first item in the array is the first upcoming renewal (sorted in the container).
    const earliestRenewal = this.props.upcomingRenewals[ 0 ];

    if ( this.state.hideNotification || ! earliestRenewal ) {
      return null;
    }

    return (
      <Card className={ styles.messageContainer }>
        <XMarkIcon
          className={ styles.closeButton }
          onClick={ this.onCrossClick }
          aria-label={ this.props.intl.formatMessage( messages.close ) }
        />
        <div className={ styles.imageTextContainer }>
          <img className={ styles.renewalImage } src={ renewalNotification } />
          <div className={ styles.inlineBlock }>
            <Title>
              <FormattedMessage { ...messages.header } />
            </Title>
            <FormattedMessage
              { ...messages.description }
            />
          </div>
        </div>
        { this.listUpcomingRenewals( this.props.upcomingRenewals ) }
      </Card>
    );
  }
}


RenewalNotification.propTypes = {
  intl: intlShape.isRequired,
  upcomingRenewals: PropTypes.array,
  loadData: PropTypes.func.isRequired,
  allSubscriptions: PropTypes.object,
};

RenewalNotification.defaultProps = {
  upcomingRenewals: [],
  allSubscriptions: {},
};

export default injectIntl( RenewalNotification );
