import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { LogoutButton } from "../../components/Button";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import ErrorDisplay, { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { UserContext } from "../../context/UserContext";
import { cancelImpersonation } from "shared-frontend/functions/auth";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";

import styles from "./styles.scss";


const messages = defineMessages( {
	signout: {
		id: "signout",
		defaultMessage: "Sign out",
	},
	signingout: {
		id: "signout.pending",
		defaultMessage: "Signing out...",
	},
	impersonating: {
		id: "userProfile.impersonating",
		defaultMessage: "Impersonating:",
	},
	stopImpersonation: {
		id: "userProfile.stopImpersonation",
		defaultMessage: "Stop impersonating",
	},
} );


/**
 * Shows info about the currently logged in user and also shows user impersonation status.
 *
 * @param {Object } props props
 *
 * @returns {JSX.Element} info about the currently logged in user.
 */
const UserProfile = ( props ) => {
	/**
	 * Gets called when the user clicks on logout.
	 *
	 * @returns {void}
	 */
	const onLogoutClick = () => {
		if ( props.loggingOut ) {
			return;
		}

		props.onLogoutClick();
	};

	const stopImpersonation = useCallback( () => {
		cancelImpersonation();
		/*
		 * Reload the page so the full state is properly filled with the new user data.
		 * We might get away with dispatching the login action for the new user, but I don't trust that
		 * we properly clean up all data from the impersonated user stored all over the Redux state.
		 */
		window.location.reload();
	}, [ cancelImpersonation, window.location.reload ] );

	const { userSession } = useContext( UserContext );

	const message = props.loggingOut ? messages.signingout : messages.signout;
	return <div className={ styles.userInfoContainer }>
		<div className={ styles.userInfo }>
			{
					userSession.impersonatedBy &&
					<FormattedMessage tagName="strong" { ...messages.impersonating } />
			}

			<p className={ styles.userEmail }>{ props.displayEmail }</p>

			{
					userSession.impersonatedBy &&
					<LogoutButton onClick={ stopImpersonation }>
						<FormattedMessage { ...messages.stopImpersonation } />
					</LogoutButton>
			}

			<span className={ styles.logOut }>
        { /* eslint-disable react/jsx-no-bind */ }
				<LogoutButton
						id="sign-out-button"
						type="button"
						onClick={ onLogoutClick }
						enabledStyle={ ! props.loggingOut }
						aria-disabled={ props.loggingOut }
				>
          <FormattedMessage { ...message } /> <ArrowSmallRightIcon/>
        </LogoutButton>
      </span>
			{ /* eslint-enable */ }
			<ErrorDisplay error={ props.logoutError } showIcon={ false }/>
		</div>
	</div>;
};

export default injectIntl( UserProfile );

UserProfile.propTypes = {
	displayEmail: PropTypes.string.isRequired,
	onLogoutClick: PropTypes.func.isRequired,
	loggedIn: PropTypes.bool,
	className: PropTypes.string,
	loggingOut: PropTypes.bool,
	logoutError: ErrorPropTypeShape,
};

UserProfile.defaultProps = {
	loggingOut: false,
	loggedIn: false,
	className: "",
	logoutError: null,
};
