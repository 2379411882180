import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { SubscriptionUpgradeTool } from "../../../containers/SubscriptionUpgradeTool";
import { Button, Modal } from "@yoast/ui-library";

import styles from "./styles.scss";

const messages = defineMessages( {
  ariaLabel: {
    id: "upgradeSubscriptionModal.aria.label",
    defaultMessage: "Upgrade subscription to bundle",
  },
  header: {
    id: "upgradeSubscriptionModal.header",
    defaultMessage: "Upgrade subscription to bundle",
  },
  successHeader: {
    id: "upgradeSubscriptionModal.successHeader",
    defaultMessage: "Successfully upgraded to a bundle!",
  },
} );

export const UpgradeSubscriptionModal = ( props ) => {
  const [ success, setSuccess ] = useState( false );

  useEffect( () => {
    if ( ! props.isOpen ) {
      setSuccess( false );
    }
  }, [ setSuccess, props.isOpen ] );

  return (
    <Modal
      isOpen={ props.isOpen }
      onClose={ props.onClose }
      aria-label={ messages.ariaLabel.defaultMessage }
    >
      <Modal.Panel>
        <Modal.Container>
          <Modal.Container.Header>
            <Modal.Title>
            {
              success
                ? <FormattedMessage { ...messages.successHeader } />
                : <FormattedMessage { ...messages.header } />
            }
            </Modal.Title>
          </Modal.Container.Header>
          <Modal.Container.Content>
            <div className={ styles.upgradeTool }>
              <SubscriptionUpgradeTool
                subscriptionId={ props.subscriptionId }
                onCancel={ props.onClose }
                onUpgradeSuccess={ () => setSuccess( true ) }
              />
            </div>
          </Modal.Container.Content>
        </Modal.Container>
      </Modal.Panel>
    </Modal>
  );
};
/* eslint-enable */
UpgradeSubscriptionModal.propTypes = {
  subscriptionId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
